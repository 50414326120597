<template>
    <v-row>
        <v-col cols="12" md="4">
            <v-card class="mx-auto">
                <v-card-title>Informações</v-card-title>
                <v-divider />
                <v-progress-linear indeterminate absolute :active="carregando" />
                <v-card-text>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-identifier</v-icon>
                        <span class="font-weight-bold">ID:</span>
                        {{dados.idfilial}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-identifier</v-icon>
                        <span class="font-weight-bold">ID CISS:</span>
                        {{dados.erp_idfilial}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-office-building</v-icon>
                        <span class="font-weight-bold">Filial:</span>
                        {{dados.filial}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-map-marker</v-icon>
                        <span class="font-weight-bold">Endereço:</span>
                        {{dados.endereco}}, nº {{dados.numero}} - {{dados.bairro}}, {{dados.complemento}}
                    </div>
                    <div class="my-3" v-if="dados.cidade">
                        <v-icon class="mr-2">mdi-city</v-icon>
                        <span class="font-weight-bold">Cidade:</span>
                        {{dados.cidade.cidade}} - {{dados.cidade.uf}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-post</v-icon>
                        <span class="font-weight-bold">CEP:</span>
                        {{dados.cep | formataCep}}
                    </div>
                    <div class="my-3">
                        <v-icon class="mr-2">mdi-phone</v-icon>
                        <span class="font-weight-bold">Contato:</span>
                        {{dados.contato | formataContato}}
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="8">
            <v-row>
                <v-col>
                    <Table
                        :pgLimitGet="5"
                        backendDirectory="usuario/listar"
                        :busca="{
                            idfilial: parseInt(idfilial),
                            busca: usuarios.busca || null
                        }"
                        :filter="true"
                        :headers="{
                            erpidusuario: {nome: 'ID CISS', tipo: 'texto'},
                            usuario: {nome: 'Usuário', tipo: 'texto'},
                            nome: {nome: 'Nome', tipo: 'texto'},
                            grupo: {nome: 'Grupo', tipo: 'objeto', campos: ['grupo']},
                            status: {nome: 'Status', tipo: 'boolean', true: 'Ativo', false: 'Inativo'},
                        }"
                    >
                        <v-col>Usuários</v-col>
                        <v-col>
                            <v-text-field
                                :disabled="carregando"
                                v-model="usuarios.busca"
                                label="Buscar"
                                outlined
                                dense
                            />
                        </v-col>
                    </Table>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Table from "../Widgets/Table";

export default {
    components: {
        Table,
    },
    name: "FilialGet",
    props: ["idfilial"],
    data: () => ({
        carregando: false,
        dados: {},
        usuarios: {},
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit"]),
    },
    methods: {
        get() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}filial/get`, {
                    idfilial: parseInt(this.idfilial),
                })
                .then((res) => {
                    this.dados = res.data;
                    this.carregando = false;
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
    },
    created() {
        this.get();
    },
};
</script>

<style scoped>
</style>